import React from 'react'

const notfound = () => {
  return (
    <div className="alert alert-warning" role="alert">
        404 : Resource indisponible
    </div>
  )
}

export default notfound